import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    container,
    left,
    contentBox,
    imageBox,
    ratio,
    titleText,
    description,
    button,
} from './about-row.module.scss';
import { IAboutRow } from '../../models/about-row.model';

import Text from '../atoms/text';
import Button from '../atoms/button';
import Markdown from '../hoc/markdown';

type TAboutRowContentPosition = 'left' | 'right';

interface IAboutRowProps {
    className?: string;
    aboutRow: IAboutRow;
    TitleTag?: React.ElementType;
    contentPosition?: TAboutRowContentPosition;
}

const AboutRow: React.FC<IAboutRowProps> = ({
    className = '',
    aboutRow,
    TitleTag = 'h2',
    contentPosition = 'right',
}) => {
    const { media, color, pathname, title, content, buttonText } = aboutRow;

    const contentPositionClass = contentPositionClasses[contentPosition];

    return (
        <div className={`${container} ${className} ${contentPositionClass}`}>
            <div className={imageBox}>
                <Image media={media} ratioClass={ratio} objectFit="scale-down" />
            </div>
            <div className={contentBox}>
                <Text Tag={TitleTag} className={titleText}>
                    {title}
                </Text>
                <Text className={description} Tag={'div'}>
                    <Markdown>{content}</Markdown>
                </Text>
                <Button as="link" color={color} to={pathname} className={button}>
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

const contentPositionClasses: Record<TAboutRowContentPosition, string> = {
    left: left,
    right: '',
};

export default AboutRow;
