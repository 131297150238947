import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import { IPage } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { IAboutRow } from '../../models/about-row.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { getNodes } from '../../utils/get-nodes';
import { useT } from '../use-translation';
import { IOfferCategory } from '../../models/offer.model';
import { relations } from '../../config/relations';

interface IUseAboutRowsQueryResult {
    allAboutImages: IQueryAllResult<ImageDataLike>;
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'type' | 'locale'>>;
    allOfferCategory: IQueryAllResult<IOfferCategory>;
}

const useAboutRows = (): IAboutRow[] => {
    const { t, locale } = useT();
    const { allAboutImages, allPage, allOfferCategory }: IUseAboutRowsQueryResult =
        useStaticQuery(query);
    const aboutImages = getNodes(allAboutImages);
    const pages = getNodes(allPage);
    const offerCategories = getNodes(allOfferCategory);
    const localePages = pages.filter((page) => page.locale === locale);
    const localOfferCategories = offerCategories.filter((category) => category.locale === locale);

    return [
        {
            aboutRowId: 1,
            color: 'yellow',
            pathname: localOfferCategories[0]?.pathname || '',
            title: t('about.row.title.0'),
            content: t('about.row.content.0'),
            buttonText: t('about.row.button.0'),
            media: [
                {
                    url: '',
                    alt: '',
                    relations: [{ relation: relations.mainImage, sequence: 0 }],
                    type: '',
                    locale: 'pl',
                    checksum: '123',
                    name: '',
                    size: 123,
                    fileId: 1,
                    localFile: aboutImages[0],
                    caption: '',
                },
            ],
        },
        {
            aboutRowId: 2,
            color: 'purple',
            pathname: localePages.find((page) => page.type === 'portfolio')?.pathname || '',
            title: t('about.row.title.1'),
            content: t('about.row.content.1'),
            buttonText: t('about.row.button.1'),
            media: [
                {
                    url: '',
                    alt: '',
                    relations: [{ relation: relations.mainImage, sequence: 0 }],
                    type: '',
                    locale: 'pl',
                    checksum: '123',
                    name: '',
                    size: 123,
                    fileId: 1,
                    localFile: aboutImages[1],
                    caption: '',
                },
            ],
        },
        {
            aboutRowId: 3,
            color: 'blue',
            pathname: localePages.find((page) => page.type === 'how-do-we-work')?.pathname || '',
            title: t('about.row.title.2'),
            content: t('about.row.content.2'),
            buttonText: t('about.row.button.2'),
            media: [
                {
                    url: '',
                    alt: '',
                    relations: [{ relation: relations.mainImage, sequence: 0 }],
                    type: '',
                    locale: 'pl',
                    checksum: '123',
                    name: '',
                    size: 123,
                    fileId: 1,
                    localFile: aboutImages[2],
                    caption: '',
                },
            ],
        },
    ];
};

const query = graphql`
    query {
        allAboutImages: allFile(
            filter: {
                relativePath: { in: ["about-row-01.png", "about-row-02.png", "about-row-03.png"] }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, width: 400)
                    }
                }
            }
        }
        allPage {
            edges {
                node {
                    type
                    pathname
                    locale
                }
            }
        }
        allOfferCategory(sort: { fields: sequence, order: ASC }, limit: 1) {
            edges {
                node {
                    ...offerCategoryFields
                }
            }
        }
    }
`;

export default useAboutRows;
