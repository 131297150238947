// extracted by mini-css-extract-plugin
export var awardList = "about-module--award-list--Xpa5H";
export var awardListBox = "about-module--award-list-box--tYY9p";
export var awardsContent = "about-module--awards-content--X7-0W";
export var awardsCounter = "about-module--awards-counter--d75dU";
export var awardsSection = "about-module--awards-section--CbB9k";
export var clientList = "about-module--client-list--WY1I4";
export var clientsDisclaimer = "about-module--clients-disclaimer--lHC4w";
export var faqContent = "about-module--faq-content--8j8gV";
export var faqCtaText = "about-module--faq-cta-text--9fuIX";
export var lead = "about-module--lead--AhXmz";
export var newsletterContent = "about-module--newsletter-content--rybVd";
export var playerList = "about-module--player-list--zTXF6";
export var rows = "about-module--rows--2rZvh";
export var sectionRows = "about-module--section-rows--qjLxD";
export var testimonialSlider = "about-module--testimonial-slider--CotwO";
export var testimonialSliderBox = "about-module--testimonial-slider-box--XxEsR";