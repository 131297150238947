import { RefObject, useEffect, useRef, useState } from 'react';

export const useIsIntersecting = (ref: RefObject<HTMLElement>, once?: boolean) => {
    const [isInViewport, setIsInViewport] = useState(false);
    const observerRef = useRef<IntersectionObserver | null>(null);
    const isInViewportOnceRef = useRef(false);

    useEffect(() => {
        const element = ref.current;

        if (!element || (once && isInViewportOnceRef.current)) return;

        const handleIntersect: IntersectionObserverCallback = (entries) => {
            entries.forEach((entry) => {
                setIsInViewport(entry.isIntersecting);
                if (entry.isIntersecting) {
                    isInViewportOnceRef.current = true;
                    if (!once || !observerRef.current) return;
                    observerRef.current.unobserve(element);
                }
            });
        };

        observerRef.current = new IntersectionObserver(handleIntersect);
        observerRef.current.observe(element);

        return () => {
            if (!observerRef.current) return;
            observerRef.current.unobserve(element);
        };
    }, [once, ref]);

    return isInViewport;
};
