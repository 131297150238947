import React from 'react';
import { graphql } from 'gatsby';

import {
    lead,
    rows,
    sectionRows,
    playerList,
    clientList,
    clientsDisclaimer,
    testimonialSlider,
    testimonialSliderBox,
    awardsSection,
    awardListBox,
    awardList,
    awardsContent,
    awardsCounter,
    faqContent,
    faqCtaText,
    newsletterContent,
} from './about.module.scss';
import { breakpoints } from '../config/breakpoints';
import { IPlayer } from '../models/player.model';
import { IPage } from '@alterpage/gatsby-plugin-alterpress-page-creator';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IClient } from '../models/client.model';
import { ITestimonial } from '../models/testimonial.model';
import { IAward } from '../models/award.model';
import { getNodes } from '../utils/get-nodes';
import { useT } from '../hooks/use-translation';
import useAboutRows from '../hooks/data/use-about-rows';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import Section from '../components/hoc/section';
import AboutRow from '../components/molecules/about-row';
import PlayerCard from '../components/molecules/player-card';
import ClientCard from '../components/molecules/client-card';
import Slider from '../components/hoc/slider';
import TestimonialCard from '../components/molecules/testimonial-card';
import AwardCard from '../components/molecules/award-card';
import Button from '../components/atoms/button';
import Markdown from '../components/hoc/markdown';
import SectionNewsletter from '../components/organisms/section-newsletter';

interface IAboutPageProps {
    readonly data: {
        allPlayer: IQueryAllResult<IPlayer>;
        allClient: IQueryAllResult<IClient>;
        allTestimonial: IQueryAllResult<ITestimonial>;
        allAward: IQueryAllResult<IAward>;
        faqPage: Pick<IPage, 'pathname'> | null;
    };
}

const AboutPage: React.FC<IAboutPageProps> = ({ data }) => {
    const { t } = useT();
    const { allPlayer, allClient, allTestimonial, allAward, faqPage } = data;
    const players = getNodes(allPlayer);
    const boardPlayers = players.filter((player) => player.isBoardMember);
    const fieldPlayers = players.filter((player) => !player.isBoardMember);
    const clients = getNodes(allClient);
    const testimonials = getNodes(allTestimonial);
    const awards = getNodes(allAward);

    const aboutRows = useAboutRows();

    return (
        <MainLayout showFloatingCircles={true}>
            <Hero
                titleProps={{ children: t('about.hero.title'), Tag: 'h1' }}
                color="pink"
                description={t('about.hero.description')}
            />
            <Section circleDisplay="none" contentClassName={sectionRows}>
                <p className={lead}>{t('about.lead')}</p>
                {aboutRows.length > 0 && (
                    <ul className={rows}>
                        {aboutRows.map((aboutRow, index) => {
                            return (
                                <li key={aboutRow.aboutRowId}>
                                    <AboutRow
                                        aboutRow={aboutRow}
                                        TitleTag="h2"
                                        contentPosition={index % 2 === 0 ? 'right' : 'left'}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                )}
            </Section>
            {faqPage && (
                <Section
                    theme="dark"
                    circleDisplay="none"
                    height="auto"
                    titleProps={{
                        Tag: 'h2',
                        children: t('about.faq.teaser.title'),
                        subtitle: t('about.faq.teaser.subtitle'),
                    }}
                >
                    <div className={faqContent}>
                        <Markdown>{t('about.faq.teaser.content')}</Markdown>
                        <p className={faqCtaText}>{t('about.faq.teaser.cta')}</p>
                        <Button color="yellow" kind="normalDark" as="link" to={faqPage.pathname}>
                            {t('about.faq.teaser.button')}
                        </Button>
                    </div>
                </Section>
            )}
            <SectionNewsletter
                theme="pink"
                titleProps={{
                    Tag: 'h2',
                    children: t('about.newsletter.title'),
                    subtitle: t('about.newsletter.subtitle'),
                }}
                buttonProps={{
                    color: 'light',
                }}
                inputTheme="semitransparent"
            >
                <h3 className={newsletterContent}>{t('about.newsletter.content')}</h3>
            </SectionNewsletter>
            {(boardPlayers.length > 0 || fieldPlayers.length > 0) && (
                <Section
                    theme="dark"
                    circleDisplay="none"
                    titleProps={{ Tag: 'h2', children: t('about.team.title') }}
                >
                    {boardPlayers.length > 0 && (
                        <ul className={playerList}>
                            {boardPlayers.map((player) => {
                                return (
                                    <li key={player.playerId}>
                                        <PlayerCard player={player} />
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    {fieldPlayers.length > 0 && (
                        <ul className={playerList}>
                            {fieldPlayers.map((player) => {
                                return (
                                    <li key={player.playerId}>
                                        <PlayerCard player={player} />
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </Section>
            )}
            {clients.length > 0 && (
                <Section
                    theme="light"
                    contentDisplay="center"
                    titleProps={{ Tag: 'h2', children: t('about.clients.title') }}
                    circleDisplay="none"
                    height="auto"
                    gridType={'narrow'}
                >
                    <ul className={clientList}>
                        {clients.map((client) => {
                            return (
                                <li key={client.clientId}>
                                    <ClientCard client={client} />
                                </li>
                            );
                        })}
                    </ul>
                    <p className={clientsDisclaimer}>{t('about.clients.disclaimer')}</p>
                </Section>
            )}
            {testimonials.length > 0 && (
                <Section
                    className={awardsSection}
                    theme="light"
                    circleDisplay="none"
                    contentDisplay="center"
                    contentWidth="normal"
                    height="auto"
                    titleProps={{ Tag: 'h2', children: t('about.testimonials.title') }}
                >
                    <div className={testimonialSliderBox}>
                        <Slider
                            className={testimonialSlider}
                            slidesPerView={2}
                            loop={true}
                            breakpoints={{
                                [breakpoints.phone]: { slidesPerView: 4 },
                                [breakpoints.tablet]: { slidesPerView: 5 },
                                [breakpoints.iPad]: { slidesPerView: 6 },
                            }}
                        >
                            {testimonials.map((testimonial) => {
                                return (
                                    <TestimonialCard
                                        key={testimonial.testimonialId}
                                        testimonial={testimonial}
                                    />
                                );
                            })}
                        </Slider>
                    </div>
                </Section>
            )}
            {awards.length > 0 && (
                <Section
                    titleProps={{ Tag: 'h2', children: t('about.awards.title') }}
                    theme="dark"
                    circleDisplay="none"
                    contentDisplay="center"
                    contentClassName={awardsContent}
                >
                    <div className={awardListBox}>
                        <span className={awardsCounter}>{awards.length}</span>
                        <ul className={awardList}>
                            {awards.map((award) => {
                                return (
                                    <li key={award.awardId}>
                                        <AwardCard award={award} />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </Section>
            )}
        </MainLayout>
    );
};

export const query = graphql`
    query ($locale: String!) {
        allPlayer(filter: { locale: { eq: $locale } }, sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...playerFields
                }
            }
        }
        allClient(filter: { locale: { eq: $locale } }, sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...clientFields
                }
            }
        }
        allTestimonial(
            filter: { locale: { eq: $locale } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...testimonialFields
                }
            }
        }
        allAward(filter: { locale: { eq: $locale } }, sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...awardFields
                }
            }
        }
        faqPage: page(locale: { eq: $locale }, type: { eq: "faq" }) {
            pathname
        }
    }
`;

export default AboutPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
