import React from 'react';
import { getMediaByRelation, Image } from '@alterpage/gatsby-plugin-image';

import { container, ratio } from './testimonial-card.module.scss';
import { relations } from '../../config/relations';
import { ITestimonial } from '../../models/testimonial.model';

interface ITestimonialCardProps {
    className?: string;
    testimonial: ITestimonial;
}

const TestimonialCard: React.FC<ITestimonialCardProps> = ({ className = '', testimonial }) => {
    const { media, title } = testimonial;
    const docItem = getMediaByRelation({ media, relation: relations.doc, useFallback: false });

    const Tag = docItem?.url ? 'a' : 'div';
    const tagProps = docItem?.url
        ? { href: docItem.url, target: '_blank', rel: 'noopener noreferrer' }
        : {};

    return (
        <Tag className={`${container} ${className}`} title={title} {...tagProps}>
            <Image media={media} ratioClass={ratio} objectFit="contain" />
        </Tag>
    );
};

export default TestimonialCard;
