import React from 'react';

import { container, titleText, descriptionText } from './award-card.module.scss';
import { IAward } from '../../models/award.model';

interface IAwardCardProps {
    className?: string;
    award: IAward;
    TitleTag?: React.ElementType;
}

const AwardCard: React.FC<IAwardCardProps> = ({ className = '', award, TitleTag = 'h2' }) => {
    return (
        <div className={`${container} ${className}`}>
            <TitleTag className={titleText}>{award.title}</TitleTag>
            <p className={descriptionText}>{award.description}</p>
        </div>
    );
};

export default AwardCard;
