import React, { useEffect, useRef, useState } from 'react';
import { motion, Variant } from 'framer-motion';

import {
    container,
    pink,
    purple,
    yellow,
    blue,
    enter,
    out,
    mainLine,
    splashLine,
} from './doodle.module.scss';
import { TAccentColor } from '../../models/accent-color.model';

type TDoodleVariant = 'initial' | 'enter' | 'out';

export interface IDoodleProps {
    className?: string;
    color?: TAccentColor;
    makeSplash?: boolean;
    onSplashEnd?(): void;
}

const Doodle: React.FC<IDoodleProps> = ({
    className = '',
    color = 'purple',
    makeSplash = false,
    onSplashEnd,
}) => {
    const isAnimatingRef = useRef(false);
    const [animationVariant, setAnimationVariant] = useState<TDoodleVariant>('initial');

    const handleAnimationComplete = (definition: TDoodleVariant) => {
        if (definition === 'enter') {
            setAnimationVariant('out');
        }
        if (definition === 'out') {
            setAnimationVariant('initial');
            isAnimatingRef.current = false;
            if (typeof onSplashEnd !== 'function') return;
            onSplashEnd();
        }
    };

    const colorClass = colorClasses[color];
    const animationClass = animationClasses[animationVariant];

    useEffect(() => {
        if (makeSplash && !isAnimatingRef.current) {
            setAnimationVariant('enter');
            isAnimatingRef.current = true;
        }
    }, [makeSplash]);

    return (
        <div className={`${container} ${className} ${colorClass} ${animationClass}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 261.544 159.935">
                <g transform="translate(425.004 -3555.54)">
                    <motion.path
                        className={mainLine}
                        d="M-419,3619s.012-34.476,22.31-61.765c14.31-15.974,38.271-41.267,83.2-37.273,24.894,1.229,52.383,11.819,75.211,40.934,8.316,10.606,18.636,34.611,18.969,58.1"
                        transform="translate(4 46)"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-width="20"
                        variants={mainLineVariants}
                        initial="initial"
                        animate={animationVariant}
                        onAnimationComplete={handleAnimationComplete}
                    />
                    <motion.path
                        className={splashLine}
                        d="M-203.673,3667.6l-27.271,13.669"
                        transform="translate(-29.434 1.38)"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-width="10"
                        variants={splashLineVariants}
                        initial="initial"
                        animate={animationVariant}
                    />
                    <motion.path
                        className={splashLine}
                        d="M-230.943,3667.6l.043,27.822"
                        transform="translate(15.632 15.049)"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-width="10"
                        variants={splashLineVariants}
                        initial="initial"
                        animate={animationVariant}
                    />
                    <motion.path
                        className={splashLine}
                        d="M-230.943,3667.6l24.762,11.349"
                        transform="translate(36.094)"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-width="10"
                        variants={splashLineVariants}
                        initial="initial"
                        animate={animationVariant}
                    />
                    <motion.path
                        className={splashLine}
                        d="M-230.943,3667.6l25.867,13.146"
                        transform="matrix(0.883, 0.469, -0.469, 0.883, 1724.065, 550.895)"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-width="10"
                        variants={splashLineVariants}
                        initial="initial"
                        animate={animationVariant}
                    />
                    <motion.path
                        className={splashLine}
                        d="M0,12.959,26.22,0"
                        transform="matrix(-0.883, 0.469, -0.469, -0.883, -221.965, 3692.211)"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-width="10"
                        variants={splashLineVariants}
                        initial="initial"
                        animate={animationVariant}
                    />
                </g>
            </svg>
        </div>
    );
};

const animationClasses: Record<TDoodleVariant, string> = {
    enter: enter,
    out: out,
    initial: '',
};

const colorClasses: Record<TAccentColor, string> = {
    pink,
    purple,
    yellow,
    blue,
};

const mainLineVariants: Record<TDoodleVariant, Variant> = {
    initial: {
        pathLength: 0,
        pathOffset: 0,
        transition: {
            duration: 0,
        },
    },
    enter: {
        pathLength: 1,
        pathOffset: 0,
        transition: {
            duration: 0.4,
        },
    },
    out: {
        pathLength: 1,
        pathOffset: 1,
        transition: {
            duration: 0.4,
        },
    },
};

const splashLineVariants: Record<TDoodleVariant, Variant> = {
    initial: {
        pathLength: 0,
        pathOffset: 0,
        transition: {
            duration: 0,
        },
    },
    enter: {
        pathLength: 1,
        pathOffset: 0,
        transition: {
            delay: 0.4,
            duration: 0.4,
        },
    },
    out: {
        pathLength: 1,
        pathOffset: 1,
        transition: {
            duration: 0.4,
        },
    },
};

export default Doodle;
