import React, { useState, useRef } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/css';

import { container, containerWithArrows, button, buttonIcon } from './slider.module.scss';
import { useT } from '../../hooks/use-translation';

import ArrowLeftIcon from '../../assets/images/svg/arrow-left.svg';
import ArrowRightIcon from '../../assets/images/svg/arrow-right.svg';

import Button from '../atoms/button';

export interface ISliderProps extends Omit<SwiperProps, 'modules'> {
    children?: React.ReactNode;
    showArrows?: boolean;
}

export interface ISlideData {
    isActive: boolean;
    isVisible: boolean;
    isDuplicate: boolean;
    isPrev: boolean;
    isNext: boolean;
}

const Slider: React.FC<ISliderProps> = ({ children, showArrows = false, ...rest }) => {
    const { t } = useT();
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const [isSlider, setIsSlider] = useState<boolean>(false);
    const swiperRef = useRef<SwiperCore | null>(null);

    const getSlide = (isNextSlide = true): void => {
        isNextSlide ? swiperRef.current?.slideNext() : swiperRef.current?.slidePrev();
    };

    const handleOnSlideChangeTransitionStart = () => setIsChanging(true);
    const handleOnSlideChangeTransitionEnd = () => setIsChanging(false);
    const handleOnBeforeInit = (swiper: SwiperCore) => {
        swiperRef.current = swiper;
    };

    const handleResize = (swiper: SwiperCore) => {
        const slidesPerView = swiper.params.slidesPerView || 1;
        setIsSlider(Array.isArray(children) && children.length > slidesPerView);
    };

    return (
        <div className={isSlider && showArrows ? containerWithArrows : container}>
            <Swiper
                modules={[Autoplay]}
                onSlideChangeTransitionStart={handleOnSlideChangeTransitionStart}
                onSlideChangeTransitionEnd={handleOnSlideChangeTransitionEnd}
                onBeforeInit={handleOnBeforeInit}
                onResize={handleResize}
                {...rest}
            >
                {Array.isArray(children) &&
                    children.map((child, index) => {
                        return (
                            <SwiperSlide
                                key={(child.key && `slide-${child.key}`) || `slide-${index}`}
                            >
                                {(slideData: ISlideData) => {
                                    return React.cloneElement(child, {
                                        ...(typeof child.type === 'function' ? { slideData } : {}),
                                    });
                                }}
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
            <Button
                kind={'icon'}
                hasArrow={true}
                className={button}
                onClick={() => getSlide(false)}
                aria-label={t('button.prev')}
                disabled={isChanging}
            >
                <ArrowLeftIcon className={buttonIcon} />
            </Button>
            <Button
                kind={'icon'}
                hasArrow={true}
                className={button}
                onClick={() => getSlide()}
                aria-label={t('button.next')}
                disabled={isChanging}
            >
                <ArrowRightIcon className={buttonIcon} />
            </Button>
        </div>
    );
};

export default Slider;
