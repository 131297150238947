// extracted by mini-css-extract-plugin
export var container = "player-card-module--container--Rk1Tc";
export var doodle = "player-card-module--doodle--NxHLC";
export var doodle01 = "player-card-module--doodle-01--3K+tZ";
export var doodle02 = "player-card-module--doodle-02--LzFW6";
export var doodle03 = "player-card-module--doodle-03--0MVHa";
export var doodle04 = "player-card-module--doodle-04--MhjUf";
export var header = "player-card-module--header--9SwtI";
export var imageBox = "player-card-module--image-box--K567y";
export var nameText = "player-card-module--name-text--gyeGl";
export var positionText = "player-card-module--position-text--Ug70M";
export var ratio = "player-card-module--ratio--EMfJk";