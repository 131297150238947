import React, { Suspense, useRef } from 'react';

import { form, formBox, spacer } from './section-newsletter.module.scss';
import { ITitleProps } from '../atoms/title';
import { INewsletterFormProps } from '../molecules/newsletter-form';
import { useIsIntersecting } from '../../hooks/use-is-intersecting';

import Section, { ISectionProps } from '../hoc/section';

const NewsletterForm = React.lazy(() => import('../molecules/newsletter-form'));

interface ISectionCareerProps {
    className?: string;
    theme?: ISectionProps['theme'];
    titleProps?: ITitleProps;
    inputLabel?: string;
    inputTheme?: INewsletterFormProps['inputTheme'];
    errorColor?: INewsletterFormProps['errorColor'];
    buttonProps?: INewsletterFormProps['buttonProps'];
    children?: React.ReactNode;
    contentBoxClassName?: string;
}

const SectionNewsletter: React.FC<ISectionCareerProps> = ({
    className = '',
    titleProps,
    theme,
    buttonProps,
    children,
    inputLabel,
    inputTheme,
    errorColor,
    contentBoxClassName,
}) => {
    const formBoxRef = useRef<HTMLDivElement>(null);

    const isFormInViewport = useIsIntersecting(formBoxRef, true);

    return (
        <Section
            className={className}
            theme={theme}
            contentDisplay="center"
            circleDisplay="none"
            titleProps={titleProps}
            height="auto"
            contentBoxClassName={contentBoxClassName}
        >
            {children}
            <div ref={formBoxRef} className={formBox}>
                {isFormInViewport && (
                    <Suspense fallback={<span className={spacer} />}>
                        <NewsletterForm
                            className={form}
                            buttonProps={buttonProps}
                            inputLabel={inputLabel}
                            inputTheme={inputTheme}
                            errorColor={errorColor}
                        />
                    </Suspense>
                )}
            </div>
        </Section>
    );
};

export default SectionNewsletter;
