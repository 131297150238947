import { getRandomInteger } from './get-random-integer';

export function getArrayInRandomSequence<T>(array: T[]): T[] {
    const tempArray: T[] = [...array];
    const newArray: T[] = [];
    while (tempArray.length) {
        const randomIndex = getRandomInteger(0, tempArray.length - 1);
        const randomItem = tempArray[randomIndex];
        newArray.push(randomItem);
        tempArray.splice(randomIndex, 1);
    }
    return newArray;
}
